import jquery from 'jquery';

// document.addEventListener('DOMContentLoaded', function () {
//   var searchbar = document.getElementById('searchbar');
//   var donateButtons = document.getElementById('donate-buttons');
//
//   searchbar.focus();
//
//   donateButtons.addEventListener('click', function (e) {
//     if (e.target.classList.contains('btn-blue')) {
//       e.preventDefault();
//       var activeButton = document.querySelector('.btn-blue.active');
//       if (activeButton) {
//         activeButton.classList.remove('active');
//       }
//
//       var otherInput = document.getElementById('other-input');
//       otherInput.style.display = 'none';
//
//       var otherButton = document.getElementById('other');
//       otherButton.style.display = 'block';
//
//       e.target.classList.add('active');
//
//       var value = e.target.getAttribute('data-dollars');
//       var paragraph = e.target.closest('div').querySelector('p');
//       paragraph.textContent = "" + value;
//
//       var inputField = document.querySelector('#other-input input');
//       inputField.value = '';
//
//       var dollar = e.target.getAttribute('data-dollars');
//       document.getElementById('amount').value = "" + dollar;
//     }
//   });
//
//   var greenButton = document.querySelector('.btn-green');
//   greenButton.addEventListener('click', function () {
//     var dollar;
//     var otherInputValue = document.querySelector('#other-input input').value;
//     if (!otherInputValue) {
//       dollar = document.querySelector('.btn-blue.active').getAttribute('data-dollars');
//     } else if (otherInputValue.trim() === '' || isNaN(otherInputValue)) {
//       alert('Please enter a dollar amount.');
//       return;
//     } else {
//       dollar = otherInputValue;
//     }
//     document.getElementById('amount').value = "" + dollar;
//   });
//
//   var otherButton = document.getElementById('other');
//   otherButton.addEventListener('click', function (e) {
//     e.preventDefault();
//
//     var buttons = e.target.parentElement.querySelector('#donate-buttons');
//     var activeButton = buttons.querySelector('.active');
//     if (activeButton) {
//       activeButton.classList.remove('active');
//     }
//
//     e.target.style.display = 'none';
//
//     var otherInput = e.target.parentElement.querySelector('#other-input');
//     otherInput.style.display = 'block';
//
//     var inputField = otherInput.querySelector('input');
//     inputField.focus();
//
//     var pText = buttons.parentElement.querySelector('p');
//     pText.textContent = 'Thank you!';
//
//     var oValue = inputField;
//     oValue.addEventListener('keyup', function () {
//       if (oValue.value > 50) {
//         pText.textContent = "Thank you! Your donation covers housing and counseling services for " + oValue.value / 25 + " people.";
//       } else {
//         pText.textContent = 'Thank you!';
//       }
//     });
//   });
// });


jquery(document).ready(function(){
  jquery('#searchbar').focus();
  jquery('#donate-buttons').on('click', '.btn-blue', function(e) {
    e.preventDefault();
    jquery('.active').removeClass('active');
    jquery('#other-input').hide().siblings('#other').show();
    jquery(this).filter('.btn-blue').addClass("active");
    jquery(this).closest('div').find('p').text("");
    jquery('#other-input').find('input').val('');
    const dollar = jquery('.active').data('dollars');
    jquery('#amount').val(""+dollar);
  });

  jquery('.btn-green').on('click', function() {
    var dollar;
    var input = jquery('#other-input').find('input').val();
    if ( !input ) {
      dollar = jquery('.active').data('dollars');
     } else if ( jquery.trim(input) === '' || isNaN(input)) {
      alert("Please enter a dollar amount.")
      // e.preventDefault();
    } else {
      dollar = input;
    }
    jquery('#amount').val(""+dollar);
  });

  jquery('#other').on('click', function(e) {
    e.preventDefault();
    var buttons = jquery(this).parent('#donate-buttons');
    buttons.find('.active').removeClass('active');
    var other = jquery(this).hide().siblings('#other-input');
    other.show();
    other.find('input').focus();
    var pText = buttons.siblings('p');
    pText.text("Thank you!");
    var oValue = other.find('input');
    oValue.keyup(function() {
      if ( oValue.val() > 50 ) {
        pText.text("Thank you!" + " You're donation covers housing and counseling services for " + oValue.val()/25 + " people.");
      } else {
        pText.text("Thank you!");
      }
    });
  });

});
